<template>
  <v-dialog v-model="dialog" max-width="700px" :persistent="loading" scrollable>
    <v-card>
      <v-card-title>
        <span class="text-h5">{{ user.id ? 'Editar' : 'Novo' }} usuário</span>
      </v-card-title>

      <v-card-text>
        <v-form v-model="valid" ref="formEl">
          <v-stepper v-model="step" vertical class="elevation-0">
            <template v-for="(s, i) in steps">
              <v-stepper-step :key="`ss-${i}`" :complete="s > i + 1" :step="s.step" :editable="false" class="pl-4">
                {{ s.title }}
              </v-stepper-step>
              <v-stepper-content :step="s.step" :key="`sc-${i}`" class="ml-7">
                <component :is="s.component" v-model="user"></component>
              </v-stepper-content>
            </template>
          </v-stepper>
        </v-form>
      </v-card-text>

      <v-card-actions class="px-8 pb-8">
        <v-spacer></v-spacer>
        <v-btn v-if="step == 1" color="primary" class="mr-2 mt-2" outlined @click="close" :disabled="loading">
          Cancelar
        </v-btn>
        <v-btn v-else color="primary" class="mr-2 mt-2" outlined @click="step--" :disabled="loading">Voltar</v-btn>
        <v-btn v-if="step < steps.length" color="primary" class="mt-2" @click="step++" :disabled="loading || !valid">
          Proximo
        </v-btn>
        <v-btn v-else color="primary" class="mt-2" @click="save" :loading="loading" :disabled="!valid">Salvar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import USER from '@/services/user'
import { mapGetters } from 'vuex'
import UserIdentify from '@/components/modals/userComponents/UserIdentify.vue'
import UserPermissions from '@/components/modals/userComponents/UserPermission.vue'

const defaultData = () => ({
  name: '',
  email: '',
  password: '',
  role: '',
  phone: '',
  active: true,
  permissions: [],
})
export default {
  components: { UserIdentify, UserPermissions },
  data() {
    return {
      step: 1,
      dialog: false,
      loading: false,
      valid: false,
      user: defaultData(),
    }
  },
  methods: {
    async save() {
      try {
        this.loading = true
        var data = {
          name: this.user.name,
          email: this.user.email,
          password: this.user.password,
          phone: this.user.phone,
          role: this.user.role,
          active: this.user.active,
          permission: this.user.permissions.reduce((acc, p) => acc + BigInt(p), 0n).toString(),
        }

        var response
        if (!this.user.id) {
          response = await USER.create(data)
        } else {
          response = await USER.update({ id: this.user.id, ...data })
        }
        this.$emit('success', response.user)
        this.close()
      } catch (error) {
        console.log(error)
        this.$toast.fire({
          icon: 'error',
          title: error.message || 'Erro ao salvar usuário',
        })
        this.$emit('error', error)
      } finally {
        this.loading = false
      }
    },
    open(data) {
      this.step = 1
      this.dialog = true
      this.user = Object.assign(defaultData(), data)
      if (data) this.user.permissions = this.decomposePermissions(data.permission)
    },
    close() {
      this.step = 1
      this.loading = false
      this.dialog = false
      this.client = defaultData()
      this.$refs.formEl.reset()
    },
    decomposePermissions(permission) {
      let permissions = []

      permission = BigInt(permission)
      Object.keys(this.permissions.permissions).forEach(key => {
        key = BigInt(key)
        if ((permission & key) == key) {
          permissions.push(`${key}`)
        }
      })
      return permissions
    },
  },
  computed: {
    ...mapGetters('auth', ['hasPermission', 'permissions']),
    steps() {
      const steps = [
        {
          step: '1',
          title: 'Dados do usuário',
          icon: 'mdi-account',
          component: 'user-identify',
        },
      ]
      if (this.hasPermission(8388608)) {
        steps.push({
          step: '2',
          title: 'Permissões',
          icon: 'mdi-lock',
          component: 'user-permissions',
        })
      }

      return steps
    },
  },

  created() {
    this.$parent.$on(this.componentName, this.open)
  },
  props: {
    componentName: {
      type: String,
      default: 'user-modal',
    },
  },
}
</script>
