<template>
  <v-card id="settings-card">
    <!-- tabs -->
    <v-tabs v-model="tab" show-arrows>
      <v-tab v-for="(tab, i) in filteredTabs" :key="`th-${i}`">
        <v-icon size="20" class="me-3">
          {{ tab.icon }}
        </v-icon>
        <span>{{ tab.title }}</span>
      </v-tab>
    </v-tabs>

    <!-- tabs item -->
    <v-tabs-items v-model="tab">
      <v-tab-item v-for="(t, i) in filteredTabs" :key="`ti-${i}`">
        <component :is="t.component"></component>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
// demos
import detail from './Detail.vue'
import users from './Users.vue'
import documents from './DocumentModels.vue'
import readjustment from './Readjustment.vue'
import docsign from './DocSign.vue'
import servicesManagement from './ServicesManagement.vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    detail,
    users,
    documents,
    readjustment,
    docsign,
    servicesManagement
  },
  data() {
    return {
      tab: null,
      tabs: [
        // { title: 'Configurações', component: 'detail', permission: 16777216, icon: 'mdi-cog-outline' },
        { title: 'Serviços', component: 'servicesManagement', permission: 1073741824, icon: 'mdi-cog-outline' },
        { title: 'Usuários', component: 'users', permission: 1, icon: 'mdi-account-group-outline' },
        { title: 'Documentos', component: 'documents', permission: 134217728, icon: 'mdi-file-document-outline' },
        { title: 'Assinatura Digital', component: 'docsign', permission: 16777216, icon: 'mdi-file-document-outline' },
        {
          title: 'Taxas de Reajuste',
          component: 'readjustment',
          permission: 16777216,
          icon: 'mdi-file-document-outline',
        },
      ],
    }
  },
  computed: {
    ...mapGetters('auth', ['hasPermission']),
    filteredTabs() {
      return this.tabs.filter(tab => {
        if (!tab.permission) return true
        return this.hasPermission(tab.permission)
      })
    },
  },
}
</script>
