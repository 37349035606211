<template>
  <div>
    <v-data-table :headers="header" :items="items" sort-by="key" sort-desc items-per-page="5">
    </v-data-table>
  </div>
</template>

<script>
import moment from 'moment'
moment.locale('pt-br')
export default {
  data: () => ({
    months: ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'],
  }),
  computed: {
    header() {
      return [
        {
          text: "Ano",
          value: "key",
          sortable: true,
        },
        ...this.months.map(month => ({
          text: month,
          value: month,
          sortable: false,
        }))]
    },
    items() {
      const groupByYear = this.taxes.reduce((acc, tax) => {
        const year = tax.year
        if (!acc[year]) acc[year] = []
        acc[year].push(tax)
        return acc
      }, {})
      return Object.entries(groupByYear).map(([key, value]) => {
        const obj = { key }
        value.forEach(tax => {
          const month = moment(tax.month, "M").format('MMM').toLowerCase()

          // toLocalePercent
          obj[month] = (tax.value * 1).toLocaleString('pt-BR', { style: 'percent', minimumFractionDigits: 2 })
        })
        return obj
      })

    },
  },
  methods: {},
  props: {
    taxes: {
      type: Array,
      default: () => []
    }
  },
}
</script>

<style>

</style>