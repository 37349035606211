<template>
  <v-btn @click="run" :small="small" :color="currentStatus.color" :icon="icon" :disabled="disabled" :loading="loading">
    <v-icon :small="small">{{ currentStatus.icon }}</v-icon>
  </v-btn>
</template>

<script>
export default {
  data: () => ({
    status: false,
    interval: null,
  }),
  methods: {
    run() {
      if (this.status) {
        clearInterval(this.interval)
        this.status = false
        this.$emit('click')
      } else {
        this.status = true
        this.interval = setInterval(() => {
          this.status = false
        }, 3000)
      }
    },
  },

  computed: {
    currentStatus() {
      return {
        icon: this.status ? this.confirmIcon : this.deleteIcon,
        color: this.status ? this.confirmColor : this.deleteColor,
      }
    },
  },

  props: {
    deleteIcon: {
      type: String,
      default: 'mdi-delete',
    },
    confirmIcon: {
      type: String,
      default: 'mdi-delete-alert',
    },
    icon: {
      type: Boolean,
      default: true,
    },
    small: {
      type: Boolean,
      default: false,
    },
    deleteColor: {
      type: String,
      default: null,
    },
    confirmColor: {
      type: String,
      default: 'danger',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style>
</style>