<template>
  <div class="d-flex flex-column gap-2">
    <v-card v-for="([groupName, perms], i) in permGroups" :key="`pg-${i}`" outlined class="pa-2 pr-1">
      <div class="d-flex justify-space-between">
        <span class="mb-1 font-weight-medium">{{ groupName }}</span>
        <v-btn @click="toggleAll(perms)" color="primary" text small>
          {{ perms.every(p => user.permissions.includes(p.toString())) ? 'Desmarcar todos' : 'Marcar todos' }}
        </v-btn>
      </div>
      <div class="d-flex flex-wrap gap-2">
        <v-checkbox
          v-for="(p, i) in perms"
          :key="`p-${i}`"
          hide-details
          class="mt-0 pl-2"
          v-model="user.permissions"
          multiple
          :label="perm[p]"
          :value="p.toString()"
        />
      </div>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    value: {
      type: Object,
      default: () => ({ permissions: [] }),
    },
  },
  data() {
    return {
      user: this.value,
    }
  },
  methods: {
    toggleAll(perms) {
      perms = perms.map(p => p.toString())
      let hasAny = perms.some(p => this.user.permissions.includes(p))
      if (hasAny) {
        this.user.permissions = this.user.permissions.filter(p => !perms.includes(p))
      } else {
        this.user.permissions = [...this.user.permissions, ...perms]
      }
    },
  },
  computed: {
    ...mapGetters('auth', ['permissions']),
    perm() {
      return this.permissions.permissions
    },
    permGroups() {
      return Object.entries(this.permissions.permissions_groups)
    },
  },
  watch: {
    value: {
      handler(value) {
        this.user = value
      },
      deep: true,
    },
    user: {
      handler(value) {
        this.$emit('input', value)
      },
      deep: true,
    },
  },
}
</script>

