<template>
  <v-dialog v-model="dialog" :persistent="loading"  max-width="500px">
    <v-card class="pa-4">
      <v-card-title class="text-center justify-center">Tem certeza que deseja <br />excluir este usuário?</v-card-title>
      <v-card-actions class="mt-4">
        <v-spacer></v-spacer>
        <v-btn color="primary" class="mr-2 mt-2" outlined :disabled="loading" @click="close">Cancelar</v-btn>
        <v-btn color="primary" class="mt-2" :loading="loading" @click="destroy">Salvar</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import USER from '@/services/user'

export default {
  data() {
    return {
      dialog: false,
      loading: false,
      user: null
    }
  },
  methods: {
    async destroy() {
      try {
        this.loading = true
        await USER.destroy(this.user.id)
        this.$emit('success')
        this.close()
      } catch (error) {
        this.loading = false
        this.$toast.fire({
          icon: 'error',
          title: error.message || 'Erro ao excluir usuário',
        })
      }
    },
    close() {
      this.dialog = false
      this.loading = false
      this.user = null
    },
    open(user) {
      this.dialog = true
      this.user = user
    },
  },
  mounted() {
    this.$parent.$on('user-delete', this.open)
  },
}
</script>

<style></style>
