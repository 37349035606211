import http from '@/http-common'

const readjustmentTaxGetAll = async () => {
  return await http
    .get('/settings/readjustment-tax')
    .then(r => r.data)
    .catch(error => {
      throw error.response?.data || error.response || error
    })
}


export default {
  readjustmentTax: {
    getAll: readjustmentTaxGetAll,
  }
}
