<template>
  <div>
    <v-card flat class="ma-4" v-for="documentType in documentModelsTypes" :key="documentType.value" outlined>
      <v-card-title class="d-flex justify-space-between align-center">
        <div>
          <v-icon left>{{ documentType.icon }}</v-icon>
          <span>{{ documentType.text }}</span>
        </div>
        <v-btn @click="uploadModel(documentType)" small color="primary" class="my-1">
          <v-icon small left>mdi-plus</v-icon>
          Adicionar modelo
        </v-btn>
      </v-card-title>
      <v-card-text>
        <div v-for="(documentModel, index) in documentType.documentModels" :key="documentModel.id">
          <v-divider v-if="index" class="my-1" />
          <div class="d-flex align-center">
            <p class="mb-0">{{ documentModel.name }}</p>
            <v-chip class="ml-2" label small outlined :color="documentModel.active ? 'success' : 'danger'">
              {{ documentModel.active ? 'Ativo' : 'Inativo' }}
            </v-chip>
            <v-spacer />
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  icon
                  small
                  @click="documentModel.default ? undefined : setDefault(documentModel)"
                  :loading="loadingDefault === documentModel.id"
                  :disabled="!!loadingDefault"
                >
                  <v-icon small>mdi-check-decagram{{ !documentModel.default ? '-outline' : '' }}</v-icon>
                </v-btn>
              </template>
              <span>{{ documentModel.default ? 'Documento padrão' : 'Definir como documento padrão' }}</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  icon
                  small
                  @click="toggleActive(documentModel)"
                  :loading="loadingActive === documentModel.id"
                  :disabled="!!loadingActive"
                >
                  <v-icon small>mdi-file-document-{{ documentModel.active ? 'check' : 'remove' }}-outline</v-icon>
                </v-btn>
              </template>
              <span>{{ documentModel.active ? 'Inativar' : 'Ativar' }} documento</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on" small :href="documentModel.url" target="_blank">
                  <v-icon small>mdi-file-download-outline</v-icon>
                </v-btn>
              </template>
              <span>Baixar modelo</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <confirm-button
                  v-bind="attrs"
                  v-on="on"
                  small
                  icon
                  @click="deleteDocument(documentModel)"
                  :loading="loadingDelete === documentModel.id"
                  :disabled="!!loadingDelete"
                ></confirm-button>
              </template>
              <span>Apagar modelo</span>
            </v-tooltip>
          </div>
        </div>

        <!-- <v-card outlined class="d-flex justify-center align-center mt-2" style="height: 45px">
          <v-icon left>mdi-file-document-outline</v-icon>
          <h4>Adicionar arquivo</h4>
        </v-card> -->
      </v-card-text>
    </v-card>
    <document-model-upload @success="addDocumentModel" />
  </div>
</template>

<script>
import DOCUMENT_MODEL from '@/services/documentModel'
import { mapGetters, mapActions } from 'vuex'
import DocumentModelUpload from '@/components/modals/DocumentModelUpload.vue'
import ConfirmButton from '@/components/global/ConfirmButton.vue'
export default {
  components: { DocumentModelUpload, ConfirmButton },
  data: () => ({
    loading: false,
    search: '',
    types: [
      {
        value: 'contract',
        text: 'Contrato',
        icon: 'mdi-file-document',
      },
      {
        value: 'contractUpdate',
        text: 'Atualização de Contrato',
        icon: 'mdi-file-document-edit',
      },
    ],
    documentModels: [],
    loadingDefault: false,
    loadingActive: false,
    loadingDelete: false,
  }),

  methods: {
    ...mapActions('auth', ['getPermissions']),
    addDocumentModel(data) {
      this.documentModels.push(data)
    },
    async getAll() {
      try {
        this.loading = true
        const response = await DOCUMENT_MODEL.getAll()
        this.documentModels = response.documentModels
        this.loading = false
      } catch (e) {
        this.loading = false
        this.$toast.fire({
          icon: 'error',
          title: e.message || 'Erro ao buscar modelos de contratos',
        })
      }
    },
    uploadModel(type) {
      this.$emit('documento-model-upload', type)
    },
    async toggleActive(item) {
      try {
        this.loadingActive = item.id
        const response = await DOCUMENT_MODEL.setActive(item.id, !item.active)
        if (response.success)
          this.documentModels = this.documentModels.map(documentModel => {
            return {
              ...documentModel,
              active: documentModel.id === item.id ? !documentModel.active : documentModel.active,
            }
          })
        this.loadingActive = false
      } catch (e) {
        this.loadingActive = false
        this.$toast.fire({
          icon: 'error',
          title: e.message || 'Erro ao definir como modelo padrão',
        })
      }
    },
    async setDefault(item) {
      try {
        this.loadingDefault = item.id
        const response = await DOCUMENT_MODEL.setDefault(item.id)
        if (response.success)
          this.documentModels = this.documentModels.map(documentModel => {
            return {
              ...documentModel,
              default: documentModel.id === item.id,
            }
          })
        this.loadingDefault = false
      } catch (e) {
        this.loadingDefault = false
        this.$toast.fire({
          icon: 'error',
          title: e.message || 'Erro ao definir como modelo padrão',
        })
      }
    },
    async deleteDocument(item) {
      try {
        this.loadingDelete = item.id

        const response = await DOCUMENT_MODEL.delete(item.id)
        if (response.success)
          this.documentModels = this.documentModels.filter(documentModel => documentModel.id !== item.id)

        this.loadingDelete = false
      } catch (e) {
        this.loadingDelete = false
        this.$toast.fire({
          icon: 'error',
          title: e.message || 'Erro ao apagar modelo',
        })
      }
    },
  },
  computed: {
    documentModelsTypes() {
      return this.types.map(type => {
        return {
          ...type,
          documentModels: this.documentModels.filter(documentModel => documentModel.type === type.value),
        }
      })
    },
  },

  mounted() {
    this.getAll()
  },
}
</script>

<style lang="scss" scoped>
.v-card__title {
  text-align: center !important;
  display: inherit;
}
</style>
