<template>
  <v-card flat>
    <v-card-title> Taxas de reajuste </v-card-title>
    <v-card-text>
      <v-expansion-panels>
        <v-expansion-panel v-for="tax in taxes" :key="tax.key">
          <v-expansion-panel-header>
            {{ tax.name }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <readjustment-table :taxes="items[tax.key]" />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card-text>
  </v-card>
</template>

<script>
import SETTINGS from "@/services/settings"
import moment from 'moment'
import ReadjustmentTable from './ReadjustmentTable.vue'
moment.locale('pt-br')
export default {
  components: { ReadjustmentTable },
  data: () => ({
    tax: [],
    months: ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'],
    taxes: [
      { name: 'IPC-A', key: 'IPCA' },
      { name: 'IGPM', key: 'IGPM' },
      { name: 'INCC', key: 'INCC' },
      { name: 'SELIC', key: 'SELIC' },
    ],
  }),
  computed: {
    header() {
      return [
        {
          text: "Ano",
          value: "key",
        },
        ...this.months.map(month => ({
          text: month,
          value: month,
        }))]
    },
    items() {
      const typeTaxes = this.tax.reduce((acc, tax) => {
        const type = tax.type;

        if (!acc[type]) acc[type] = []

        acc[type].push(tax)

        return acc
      }, {})

      return typeTaxes

    },
  },
  methods: {
    async getTaxes() {
      try {
        this.loading = true
        const response = await SETTINGS.readjustmentTax.getAll()
        this.tax = response.readjustmentTaxes
        this.loading = false
      } catch (e) {
        this.loading = false
        this.$toast.fire({
          icon: 'error',
          title: e.message || 'Erro ao carregar taxas',
        })
      }
    }
  },
  mounted() {
    this.getTaxes()
  },
}
</script>

<style>

</style>