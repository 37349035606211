<template>
  <v-dialog v-model="dialog" max-width="500px" @click:outside="close" :persistent="loading">
    <v-card>
      <v-card-title>
        <span class="text-h5">Modelo de {{ type.text }}</span>
      </v-card-title>

      <v-card-text>
        <v-form v-model="valid">
          <v-text-field v-model="attachment.name" label="Nome do modelo" outlined dense :rules="rules.name" />
          <v-file-input
            v-model="attachment.file"
            :label="`Selecione o modelo de ${type.text}`"
            outlined
            dense
            accept=".docx"
            :rules="rules.file"
          />
        </v-form>
      </v-card-text>

      <v-card-actions class="px-8 pb-8">
        <v-spacer></v-spacer>
        <v-btn color="primary" class="mr-2 mt-2" outlined @click="close" :disabled="loading">Cancelar</v-btn>
        <v-btn color="primary" class="mt-2" @click="save" :loading="loading" :disabled="!valid">Salvar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import DOCUMENT_MODEL from '@/services/documentModel'

const defaultData = () => ({
  file: null,
  name: '',
})

export default {
  data: () => ({
    dialog: false,
    loading: false,
    valid: true,
    attachment: defaultData(),
    type: {
      value: null,
      text: null,
      icon: null,
    },
    rules: {
      file: [
        v => !!v || 'O anexo é obrigatório',
        v => (!!v && v.size <= 10485760) || 'O anexo deve ter no máximo 10MB',
      ],
      name: [v => !!v || 'O nome é obrigatório'],
    },
  }),
  methods: {
    async save() {
      try {
        this.loading = true
        if (!this.attachment.file) throw new Error('O anexo é obrigatório')
        var response = await DOCUMENT_MODEL.upload(this.type.value, this.attachment)
        this.$emit('success', response.documentModel)
        this.loading = false
        this.close()
      } catch (error) {
        console.log(error)
        this.loading = false
        this.$toast.fire({
          icon: 'error',
          title: error.message || 'Erro ao salvar pagamento',
        })
        this.$emit('error', error)
      }
    },
    open(type) {
      this.attachment = defaultData()
      this.type = type
      this.dialog = true
    },
    close() {
      if (this.loading) return
      this.dialog = false
      this.loading = false
      this.type = {
        value: null,
        text: null,
        icon: null,
      }
    },
  },
  mounted() {
    this.$parent.$on('documento-model-upload', this.open)
  },
}
</script>

<style></style>
