<template>
  <v-card flat class="mt-5">
    <v-form class="multi-col-validation">
      <div class="pa-3">
        <v-card-text>
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field v-model="socialReason" label="Razão Social" outlined dense></v-text-field>
            </v-col>

            <v-col cols="12" md="3">
              <v-text-field v-model="fantasyName" label="Nome Fantasia" outlined dense></v-text-field>
            </v-col>

            <v-col cols="12" md="3">
              <v-text-field v-model="cnpj" type="tel" label="CNPJ" outlined dense></v-text-field>
            </v-col>

            <v-col cols="10" md="6">
              <v-text-field v-model="address" label="Endereço Completo" outlined dense></v-text-field>
            </v-col>

            <v-col cols="1" class="pl-0">
              <v-btn class="mx-2" fab small color="primary" @click="showadress=!showadress">
                <v-icon dark> mdi-magnify </v-icon>
              </v-btn>
            </v-col>

            <v-col cols="12" md="2">
              <v-text-field v-model="complement" label="Complemento" outlined dense></v-text-field>
            </v-col>

            <v-col cols="12" class="py-0" v-if="showadress">
              <p class="mb-0">Selecione o Endereço:</p>
              <v-radio-group v-model="radios" class="mt-0">
                <v-radio label="Endereço A" value="radio-1"></v-radio>
                <v-radio label="Endereço B" value="radio-2"></v-radio>
              </v-radio-group>
            </v-col>

            <v-col cols="12" class="pa-0"></v-col>

            <v-col cols="12" md="3">
              <v-text-field v-model="email" type="email" label="E-mail" outlined dense></v-text-field>
            </v-col>

            <v-col cols="12" md="3">
              <v-text-field v-model="telephone" type="tel" label="Telefone" outlined dense></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-btn color="primary" class="me-3 mt-2"> Salvar </v-btn>
              <v-btn color="secondary" outlined class="mt-2"> Cancelar </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </div>
    </v-form>
  </v-card>
</template>

<script>
import { ref } from '@vue/composition-api'

export default {
  setup() {
    const socialReason = ref('C.DAHER EMPREENDIMENTOS E PARTICIPACOES LTDA')
    const fantasyName = ref('CD FACTORING COMERCIAL')
    const cnpj = ref('79.993.259/0001-30')
    const address = ref('Av. Ayrton Senna da Silva, 1055 - Guanabara, Londrina - PR, 86050-460')
    const complement = ref('Sala 1706')
    const email = ref('contato@daher.adm.br')
    const telephone = ref('(43) 3322-2119')

    return {
      socialReason,
      fantasyName,
      cnpj,
      address,
      showadress: false,
      complement,
      email,
      telephone,
    }
  },
}
</script>