var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.user)?_c('v-row',{staticClass:"mt-0"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Nome Completo","outlined":"","dense":"","hide-details":"","rules":[
        v => !!v || 'Nome é obrigatório',
        v => (v && v.length <= 255) || 'Nome deve ter no máximo 255 caracteres',
        v => (!!v && v.split(' ').length >= 2) || 'Nome deve ter no mínimo 2 palavras',
      ]},model:{value:(_vm.user.name),callback:function ($$v) {_vm.$set(_vm.user, "name", $$v)},expression:"user.name"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field-simplemask',{attrs:{"label":"Whatsapp","properties":{
        disabled: _vm.loading,
        outlined: true,
        dense: true,
        hideDetails: true,
        rules: [v => !!v || 'Obrigatório'],
      },"options":{
        inputMask: '(##) #####-####',
        outputMask: '(##) #####-####',
        empty: null,
        applyAfter: false,
      }},model:{value:(_vm.user.phone),callback:function ($$v) {_vm.$set(_vm.user, "phone", $$v)},expression:"user.phone"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"type":"email","label":"E-mail","outlined":"","dense":"","hide-details":"","rules":[
        v => !!v || 'E-mail é obrigatório',
        v => (v && v.length <= 255) || 'E-mail deve ter no máximo 255 caracteres',
        v => /.+@.+\..+/.test(v) || 'E-mail inválido',
      ]},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"type":_vm.showPassword || !_vm.user.password ? 'text' : 'password',"label":"Senha","placeholder":_vm.user.id ? 'Deixe em branco para manter a senha atual' : 'Senha é obrigatória',"outlined":"","dense":"","hide-details":"","rules":[v => !!_vm.user.id || !!v || 'E-mail é obrigatório'],"append-icon":_vm.showPassword || !_vm.user.password ? 'mdi-eye' : 'mdi-eye-off'},on:{"click:append":function($event){_vm.showPassword = !_vm.showPassword}},model:{value:(_vm.user.password),callback:function ($$v) {_vm.$set(_vm.user, "password", $$v)},expression:"user.password"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"label":"Cargo","outlined":"","dense":"","hide-details":"","items":_vm.rules,"item-text":"value","item-value":"value","rules":[v => !!v || 'Perfil é obrigatório']},model:{value:(_vm.user.role),callback:function ($$v) {_vm.$set(_vm.user, "role", $$v)},expression:"user.role"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-switch',{staticClass:"mt-0 pl-2 pb-1",attrs:{"label":_vm.user.active ? 'Ativado' : 'Desativado',"dense":"","hide-details":""},model:{value:(_vm.user.active),callback:function ($$v) {_vm.$set(_vm.user, "active", $$v)},expression:"user.active"}})],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }