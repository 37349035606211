<template>
  <v-row class="mt-0" v-if="user">
    <v-col cols="12">
      <v-text-field
        v-model="user.name"
        label="Nome Completo"
        outlined
        dense
        hide-details
        :rules="[
          v => !!v || 'Nome é obrigatório',
          v => (v && v.length <= 255) || 'Nome deve ter no máximo 255 caracteres',
          v => (!!v && v.split(' ').length >= 2) || 'Nome deve ter no mínimo 2 palavras',
        ]"
      ></v-text-field>
    </v-col>
    <v-col cols="12">
      <v-text-field-simplemask
        v-model="user.phone"
        label="Whatsapp"
        :properties="{
          disabled: loading,
          outlined: true,
          dense: true,
          hideDetails: true,
          rules: [v => !!v || 'Obrigatório'],
        }"
        :options="{
          inputMask: '(##) #####-####',
          outputMask: '(##) #####-####',
          empty: null,
          applyAfter: false,
        }"
      />
    </v-col>
    <v-col cols="12">
      <v-text-field
        v-model="user.email"
        type="email"
        label="E-mail"
        outlined
        dense
        hide-details
        :rules="[
          v => !!v || 'E-mail é obrigatório',
          v => (v && v.length <= 255) || 'E-mail deve ter no máximo 255 caracteres',
          v => /.+@.+\..+/.test(v) || 'E-mail inválido',
        ]"
      ></v-text-field>
    </v-col>
    <v-col cols="12">
      <v-text-field
        v-model="user.password"
        :type="showPassword || !user.password ? 'text' : 'password'"
        label="Senha"
        :placeholder="user.id ? 'Deixe em branco para manter a senha atual' : 'Senha é obrigatória'"
        outlined
        dense
        hide-details
        :rules="[v => !!user.id || !!v || 'E-mail é obrigatório']"
        :append-icon="showPassword || !user.password ? 'mdi-eye' : 'mdi-eye-off'"
        @click:append="showPassword = !showPassword"
      ></v-text-field>
    </v-col>
    <v-col cols="12">
      <v-select
        v-model="user.role"
        label="Cargo"
        outlined
        dense
        hide-details
        :items="rules"
        item-text="value"
        item-value="value"
        :rules="[v => !!v || 'Perfil é obrigatório']"
      ></v-select>
    </v-col>
    <v-col cols="12">
      <v-switch
        class="mt-0 pl-2 pb-1"
        v-model="user.active"
        :label="user.active ? 'Ativado' : 'Desativado'"
        dense
        hide-details
      ></v-switch>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    value: {
      type: Object,
      default: () => ({ name, email, role, permissions: [] }),
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      user: this.value || { name: '', email: '', role: '', permissions: [] },
      showPassword: false,
      rules: [
        {
          value: 'Administrador',
          permissions: [
            1, 2, 4, 8, 16, 32, 64, 128, 256, 512, 1024, 2048, 4096, 8192, 16384, 32768, 65536, 131072, 262144, 524288,
            1048576, 2097152, 4194304, 8388608, 16777216, 33554432, 67108864, 134217728, 268435456, 536870912,
            1073741824, 2147483648, 4294967296,
          ].map(p => p.toString()),
        },
        {
          value: 'Gerente',
          permissions: [
            16, 32, 64, 128, 256, 512, 1024, 2048, 4096, 8192, 16384, 32768, 65536, 131072, 262144, 524288, 1048576,
            2097152, 4194304, 8388608, 16777216, 33554432, 67108864, 134217728, 268435456, 536870912, 1073741824,
            2147483648, 4294967296,
          ].map(p => p.toString()),
        },
        {
          value: 'Vistoriador',
          permissions: [536870912].map(p => p.toString()),
        },
        {
          value: 'Prestador de Serviço',
          permissions: [4294967296].map(p => p.toString()),
        },
      ],
    }
  },
  computed: {
    ...mapGetters('auth', ['permissions']),
  },
  watch: {
    value: {
      handler(value) {
        this.user = value
      },
      deep: true,
    },
    user: {
      handler(value, oldValue) {
        const role = this.rules.find(r => r.value === value.role)
        if (role) value.permissions = role.permissions

        this.$emit('input', value)
      },
      deep: true,
    },
  },
}
</script>

<style></style>
