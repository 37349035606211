<template>
  <v-dialog v-model="dialog" max-width="500px">
    <v-card>
      <v-card-title>
        <span class="text-h5" v-if="!service.id">Adicionar Serviço</span>
        <span class="text-h5" v-else>Editar Serviço</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-form ref="form" v-model="valid" @submit.prevent="save">
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="service.name"
                  label="Nome"
                  outlined
                  hide-details
                  :rules="[v => !!v || 'Nome é obrigatório']"
                />
              </v-col>

              <v-col cols="12">
                <v-textarea v-model="service.description" label="Descrição" outlined hide-details />
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>

      <v-card-actions class="px-8 pb-8">
        <v-switch v-if="service.id" v-model="service.active" label="Ativo" hide-details />
        <v-spacer></v-spacer>
        <v-btn color="primary" class="mr-2 mt-2" outlined :disabled="loading" @click="close">Cancelar</v-btn>
        <v-btn color="primary" class="mt-2" @click="save" :loading="loading" :disabled="!valid">Salvar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import SERVICE from '@/services/services'
const defaultData = () => ({
  name: '',
  description: '',
  active: true,
})
export default {
  data() {
    return {
      loading: false,
      dialog: false,
      valid: false,
      service: defaultData(),
    }
  },
  methods: {
    async save() {
      try {
        this.loading = true
        if (!this.service.name) throw new Error('Nome é obrigatório')

        if (this.service.id) {
          await SERVICE.update(this.service.id, {
            name: this.service.name,
            description: this.service.description,
            active: this.service.active,
          })
        } else {
          await SERVICE.create({
            name: this.service.name,
            description: this.service.description,
            active: this.service.active,
          })
        }
        this.$emit('success')
        this.close()
      } catch (error) {
        console.log(error)
        this.$toast.fire({
          icon: 'error',
          title: error.message || 'Erro ao salvar imóvel',
        })
        this.$emit('error', error)
        this.loading = false
      }
    },
    open(data) {
      this.dialog = true
      this.service = Object.assign(defaultData(), data)
    },
    close() {
      this.loading = false
      this.dialog = false
      this.service = defaultData()
    },
  },
  created() {
    this.$parent.$on(this.componentName, this.open)
  },
  props: {
    componentName: {
      type: String,
      default: 'service-modal',
    },
  },
}
</script>

<style></style>
